exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-blog-viewport-resolution-js": () => import("./../../../src/pages/blog/viewport-resolution.js" /* webpackChunkName: "component---src-pages-blog-viewport-resolution-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-cv-cv-js": () => import("./../../../src/templates/cv/cv.js" /* webpackChunkName: "component---src-templates-cv-cv-js" */),
  "component---src-templates-single-portfolio-single-portfolio-js": () => import("./../../../src/templates/singlePortfolio/singlePortfolio.js" /* webpackChunkName: "component---src-templates-single-portfolio-single-portfolio-js" */)
}

